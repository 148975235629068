
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

@media (min-width: 768px){
    .container, .container-sm, .container-md {
        max-width: 100%;
    }
    body .home .login-container{
        width: 720px;
    }
}
@media (min-width: 1200px){
    body .container {
        max-width: 1285px;
        min-width: 1000px;
    }
}
body .home .login-container {
    max-width: 720px;
    margin: 0 auto;
    border-radius: 24px;
}
@media (max-width: 767px){
    .container, .container-sm, .container-md {
        max-width: 785px;
    }
}
h1,h2,h3,h4,h5,h5,p{
    margin-bottom: 0;
}


body{
    font-family: 'Barlow', sans-serif;
    color: #484848;

}
body .home{
    color: #484848;
}

body .btn.btn-primary,
body .btn.btn-link{
    background: #FDB25A;
    border-radius: 12px;
    color: black;
    font-size: 20px;
    margin-left: 6px !important;
    margin-right: 6px !important;
    transition: 0.3s all ease-in-out;
    font-family: 'Barlow', sans-serif;
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    min-width: unset !important;
    box-shadow: none;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
    /* border: 1px solid #FDB25A !important; */
}
.footer-social a:hover {
    transform: scale(1.20);
}
body .btn-invert-white{
    background: transparent;
    border-radius: 6px;
    border: 1px solid white;
    font-size: 16px;
    margin-left: 8px !important;
    margin-right: 8px !important;
    transition: 0.3s all ease-in-out;
    font-family: 'Barlow', sans-serif;
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    min-width: unset !important;
    box-shadow: none;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
}

body .login-btns .btn.btn-primary{
    background: #000;
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    text-align: center;
    border-radius: 16px;
    transition:0.3s all ease-in-out;
    max-width: 205px;
    width: 100%;
    border: 1px solid black !important
}
.main-container{
    padding: 16px 0;
}
body .login-btns .btn.btn-primary:hover{
    border: 1px solid #FDB25A !important;
    background: black !important;
}
body .btn.btn-primary:hover,
body .btn.btn-link:hover,
body .btn.btn-primary:hover:enabled{
    text-decoration: none;
    background: #F4F4F4F4;
    color: #000;
}
body .login-bg{
    padding: 40px 35px 65px 35px;
    background-color: #161616;
}
.bg-new-theme {
    background-color: #161616;
}
.bg-new-theme th ,
.bg-new-theme td {
    color: #ffffff !important;
}
body .body-bg.home{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
body .body-bg{
    background-size: cover;
    background-position: center center;
    height: 100vh;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
body h1{
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.17;
    text-align: center;
    color: #F4F4F4F4;
    margin-bottom: 20px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
body h1 span{
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 1.17;
    color: #FFAC3F;
    display: inline-block;
}

body p{
    font-style: normal;
    font-weight: normal;
    /* font-size: 22px;
    line-height: 32px;
    /* text-align: center; 
    letter-spacing: -0.4px;
    color: #484848; */
}
body p.p-gold{
    color: #FFAC3F;
}
body p.p-dark-blue{
    color:#03045E;
}
body p.p-bold{
    font-weight: 600;
}

.login-text{
    color: #C2C2C2;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 55px;
}
.login-container .p-gold{
    margin: 24px 0;
}

.menu-bg{
    padding: 0 15px;
    background-color: #161616;
}
.row-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

body .statcard{
    background: #161616 !important;
    border-radius: 16px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 248px; */
    margin: 0 4px
    
}
.col-dash-left{
    flex: 0 0 40%;
    max-width: 40%;
}
.col-dash-right{
    flex: 0 0 60%;
    max-width: 60%;
}
.col-card{
    flex: 0 0 20%;
    max-width: 20%;
}
.row-delegate{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 50px;
}
.col-delegate{
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
}
.box-delegate{
    background: #000000;
    border-radius: 16px;
    padding: 24px;
    margin:0 4px;
    text-align: center;
    width: 100%;
}
.small-p{
    font-size: 17px;
    line-height: 1.3;
}

.dashboard-card .small-p{
    font-size: 17px;
    line-height: 1.3;
}
.statcard p{
    font-size: 18px;
    color:#C2C2C2;
    font-weight: 400;
    font-family: 'Questrial', sans-serif;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.statcard p.stat-value{
    font-size: 28px;
    color:#F4F4F4F4;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-bottom: 16px;
    font-weight: 600;
    /* line-height: 1.3;
    display: inline-block;
    width: 140px;
    font-size: 28px;
    font-weight: 600;
    overflow: hidden;
    word-break: break-all;
    height: 45px;
    line-height: 45px; */
}
.cards .col-card:first-child p.stat-value{
    font-size: 20px;
}
.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody{
    color: #484848;
}
body .table-admin .btn.btn-primary{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


.modal-container .modal-content .card .form-control{
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
}
.form-group label{
    font-weight: 400;
    font-size: 18px;
    font-family: 'Questrial', sans-serif;
    color: #C2C2C2;
    margin-bottom: 4px;
}
.modal-container .modal-content .card .input-group-text.text-dark{
    color: white !important;
    outline: none;
    border: none;
}
.modal-container .modal-content .card .input-group-text{
    background-color: #ffac3f;
    color: white;
}
.modal-container .modal-content .card{
    background: #161616;
    border-radius: 32px;
    box-shadow: none;
}
.modal-title{
    color: #F4F4F4F4;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 12px;
}
.modal-subtitle{
    color: #C2C2C2;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 50px;
}
.modal-content .card-body p:first-child{
    color: #F4F4F4F4;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 12px;
}
.modal-content .card-body p{
    color: #C2C2C2;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 50px;
}
.card .card-body{
    padding: 60px 80px !important
}
.table-admin .dropdown-menu{
    background-color: white;
    color: #484848;
}
.table-admin .dropdown-menu .dropdown-item{
    color: #484848;
}
.table-admin .dropdown-menu .dropdown-item.disabled{
    color: #b9b9b9
}
.show > .btn-primary.dropdown-toggle{
    color: white;
    background-color: #ffac3f;
}

.dashboard .main-container,
.owner .main-container{
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-subtitle{
    color: #A3A3A3;
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: block;
    margin-top: 20px;
}
.icon-title{
    font-family: 'Plus Jakarta Sans', sans-serif;
    color:#D4D4D4;
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin-bottom: 20px;
}
.login-btns{
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-login{
    text-align: right;
}
.close-login a{
    display: inline-block;
    padding: 16px 19px;
    background: #000000;
    border-radius: 12px;
}
.create-wallet{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    margin-top: 50px;
}
.create-wallet p{
    color: #C2C2C2;
    font-size: 16px;
}
.create-wallet a{
    color: #FDB25A;
    font-size: 16px;
    margin-left:7px;
}
body .body-bg.dashboard{
    background-size: cover;
    background-position: top center;
    background-image: url('../../images/bg-admin.png');
    height: 100%;
}
/* nav */
.nav-spacing {
    padding: 11px 0;
}
#navbarSupportedContent {
    justify-content: center;
}
body .navbar-expand-md .navbar-nav .nav-link {
    color: #ffffff;
    padding: 0 9px;
    font-size: 16px;
    transition: 0.3s all ease-in-out;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
body .navbar-expand-md .navbar-nav .nav-link:hover {
    color: #FDB25A;
}

/*The menu toggle "X" animation*/
.line {
    fill: none;
    stroke: #FFCE95;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}
.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}
.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}
.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}
.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}
/* END he menu toggle "X" animation*/
.overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-image: url('../../images/bg-mobile-expand.png');
    background-size: cover;
    background-position: bottom center;
    overflow-y: hidden;
    transition: 0.5s;
}

footer{
    padding: 60px 0;
    background: #161616;
    
}
.footer{
    max-width: 1290px;
    margin:0 auto;
}
p.footer-title{
    color: #c2c2c2;
    font-weight: 600;
}
.footer .icon-footer{
    margin-left:30px;
}
.separator-footer{
    width:100%;
    height: 1px;
    background-color: #484848;
    margin: 26px 0;
    opacity: 0.2;
}
footer .navbar-nav{
    flex-direction: row;
    justify-content: center;
}
footer ul li{
    padding: 0 12px;
    position: relative;
    color: #c2c2c2;
    font-size: 18px;
}
footer ul li a{
    color: #c2c2c2;
    transition: 0.3s all ease-in-out;
}
footer ul li a:hover{
    color: #FFAC3F;
}

.bottom-footer{
    margin-top: 30px;
}
.bottom-footer p{
    font-weight: 400;
    font-size:18px;
    color: #c2c2c2;
}
.bottom-footer ul{
    justify-content: flex-end;
}
.footer-social a{
    transition:0.3s all ease-in-out
}
.footer-social a:hover{
    transform: scale(1.20);
}
.bottom-footer .navbar-nav{
    display: flex;
}
.chainNav .icons-overlay a{
    padding: 0 12px;
    margin-bottom: 80px;
    display: inline-block;
}
.height100{
    height: 100%;
}
.height0{
    height: 0%;
}
.special-menu{
    z-index: 2;
    /* background: red; */
    position: fixed;
    top: 0;
    left:0;
    padding:11px 15px;
}
.menuspace{
    height: 84px;
    background: black;
    display: none;
}
.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}
ul li {
    font-size: 22px;
    color: white;
}
.overlay .navbar-nav a {
    padding: 0 15px 40px 15px;
    text-decoration: none;
    font-size: 18px;
    color: #fff;
    display: block;
    transition: 0.3s;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
footer ul li {
    padding: 0 12px;
    position: relative;
    color: #c2c2c2;
    font-size: 18px;
}
.bottom-footer p {
    font-weight: 400;
    font-size: 18px;
    color: #c2c2c2;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
footer ul li a {
    color: #c2c2c2 !important;
    transition: 0.3s all ease-in-out;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
footer ul li a:hover{
    text-decoration: none;
    color: #FFAC3F !important;
}
.exit-button{
    background: #000000;
    border-radius: 12px;
    padding: 13px 16px 16px 16px;
    transition: 0.3s all ease-in-out;
    border: 1px solid black;
    display: inline-block;
}
.exit-button:hover{
    border: 1px solid #FFCE95;
}
.chainnav .exit-button{
    border: 1px solid #FFCE95;
    margin-top: 10px;
}
.wallet-adress span{
    display: inline-block;
    width: 140px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-left: 8px;
    color: #F4F4F4F4;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.wallet-adress{
    padding: 14px 24px;
    background: #000000;
    border-radius: 12px;
    margin-right: 12px;
}
.chainnav .icons-overlay a {
    padding: 0 12px;
    margin-bottom: 80px;
    display: inline-block;
}
.menu-toggle {
    z-index: 99;
    background: black;
    border-radius: 12px;
    padding: 10px;
    display: flex;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
body .cards {
    margin-top:0;
    margin-bottom: 0;
}
.deleg-contract{
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.dash-head h1{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 36px;
    color: #F5F5F5;
    text-align: left;
    margin-bottom: 0;
}
.dash-head{
    margin-bottom: 45px;
}
.dashboard .main-container{
    padding: 82px 0 120px 0;
    /*height: 100vh;*/
}
.owner .main-container{
    padding: 82px 0 120px 0;
}
.dash-head p{
    color: #FFFFFF;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    align-items: end;
}
.dash-head p span{
    font-weight: 400;
    margin-left: 18px;
}
.wrap-adres-head{
    padding: 12px 18px;
    background: #161616;
    border-radius: 8px;
    /* margin-right: 8px; */
}
.head-btns{
    padding: 12px 18px;
    background: #161616;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    display: inline-block;
    margin-left:8px
}
.head-btns:hover{
    text-decoration: none;
}
.wrap-adres-head img{
    margin-left: 12px;
}
.head-btns img{
    margin-top: -4px;
    margin-left: 12px;
}
.dash-container{
    max-width: 1280px;
    margin:0 auto;
    width: 100%;
}
.admin-row{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top:8px;
}
.admin-row .col-card{
    max-width: 50%;
    flex: 0 0 50%;
}
.wrap-adres-head img{
    cursor: pointer;
}
.statcard p img{
    margin-right:8px;
}
.body-bg.home footer{
    display: none;
}
h3{
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #F4F4F4F4;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    margin-bottom: 8px;
}
.wrap-stakes-box{
    background: #161616;
    border-radius: 16px;
    padding: 32px 32px;
    margin-left:4px;
    height: 100%;
}
.wrap-stakes-box p{
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #C2C2C2;
}
.wrap-stakes-box button{
    margin-top: 10px;
}
.wrap-graph-box{
    background: #161616;
    border-radius: 16px;
    padding: 32px;
    margin-right:4px;
    height: 100%;
}
.wrap-graph-box p {
    color: #C2C2C2;
}
.wrap-graph-box h3 {
    text-align: left;
}

.apexcharts-tooltip {
    background: transparent !important;
    box-shadow: initial !important;
}
.apexcharts-tooltip .arrow_box{
    background: #fdb25a;
    padding: 2px 5px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
}

.apexcharts-gridline {
    stroke-width: 32px;
}
.apexcharts-gridline:first-of-type {
    stroke-width: 53px;
}
.apexcharts-gridline:last-of-type {
    stroke-width: 53px;
}
.apexcharts-grid-borders {
    display: none !important;
}
.apexcharts-xaxis-tick {
    display: none;
}
.margin-left-minus-14 {
    margin-left: -14px;
}
.modal-content .btn.btn-primary, .modal-content .btn.btn-link{
    margin-bottom: 16px;
}
.wrap-stakes-box p.egld-delegate{
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
    color: #F4F4F4F4;
    margin-bottom: 4px;
    font-size: 28px;
    line-height: 48px;
}
.wrap-stakes-box p.claimable-delegate{
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    color: #737373;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 28px;
}
.wrap-stakes-box p.title-delegate{
    font-family: 'Questrial', sans-serif;
    font-weight: 400;
    color: #C2C2C2;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 28px;
}
 body .box-delegate .btn.btn-primary, 
 body .box-delegate .btn.btn-link{
    font-size :16px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans', sans-serif;
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    margin-bottom: 8px !important;
    margin-top: 0 !important;
 }
 body .box-delegate .inverted-delegate .btn.btn-primary{
    background: transparent;
    border: 1px solid #FDB25A !important;
    color: #FDB25A;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
body .box-delegate .inverted-delegate .btn.btn-primary:hover{
    text-decoration: none;
    background: #F4F4F4F4;
    color: #000;
    border: 1px solid #F4F4F4F4 !important;
}
.btn-wrap-delegate{
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrap-stakes-box p.title-delegate img{
    margin-right: 6px;
}
.pb-0-important {
    padding-bottom: 0 !important;
}
/*.mt-25-important {*/
/*    margin-top: 25px !important;*/
/*}*/
.pb-0-important button {
    margin-bottom: 0 !important;
}

.login-container.login-bg-2 {
    max-width: 720px;
}
.login-container.login-bg-2 .card {
    background: #161616;
    color: #fff;
}
.nodes-card{
    background: #161616 ;
    border-radius: 16px;
    margin-top: 12px;
}
.title-nodes{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: white;
}
.btn-add-nodes{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FDB25A;
    border: 1px solid #FDB25A;
    background: transparent;
    padding: 15px 25px;
    border-radius: 12px;
}
.action-dropdown,.action-dropdown:hover,.action-dropdown:focus{
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    padding: 16px 24px !important;
    background: #161616 !important;
    border-radius: 12px;
    margin-right: 16px;
    outline: none !important;
    box-shadow: none !important;
}
.table-admin tr{
    background: #000000;
    border-radius: 12px;
    margin-bottom: 12px;
}
.table .table-admin  td{
    border-top: none;
    border-bottom: none;
}
.table-admin .dropdown-menu {
    background-color: #161616;
    color: #161616;
}
body .table-responsive .table-admin .badge{
    background: transparent;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white !important;
}
.node-list-table{
    margin-left: 16px;
}
.node-list-table span{
    font-family: 'Questrial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: white;
}
.text-node{
    width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-left: 20px;
}
.table-responsive{
    border-radius: 12px;
}
.apexcharts-canvas:before {
    content: '';
    position: absolute;
    left: 58px;
    top: 0;
    width: 27px;
    height: 90%;
    background: #161616;
    z-index: 1;
}
.apexcharts-canvas:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 14px;
    height: 90%;
    background: #161616;
    z-index: 1;
}
.apexcharts-marker {
    z-index: 999;
}
body .dd-children button.btn.btn-primary {
    padding: 0 !important;
    background: transparent !important;
}
@media screen and (max-width: 1300px){
    .bg-new-theme {
        margin: 0 15px;
    }
    .row-cards,.admin-row,.row-spacing{
        padding: 0 16px;
    }
    .btn-wrap-delegate{
        display: block;
    }
    body .box-delegate .inverted-delegate .btn.btn-primary,
    body .box-delegate .btn.btn-primary,
    body .box-delegate .btn.btn-link{
        width: 100%;
    }
}
@media screen and (max-width: 1120px){
    .col-dash-left{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-dash-right{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .row-cards-graph .col-dash-left{
        flex: 0 0 40%;
        max-width: 40%;
    }
    .row-cards-graph .col-dash-right{
        flex: 0 0 60%;
        max-width: 60%;
        
    }
    .dash-head p{
        font-size: 14px;
    }
    .dash-head h1{
        font-size: 28px;
    }
    .row-cards .col-card:first-child{
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 8px;
    }
    .col-card {
        flex: 0 0 25%;
        max-width: 25%;
    }
}
@media screen and (max-width: 992px){
    .navbar .container{
        justify-content: center;
    }
    body .btn-invert-white{
        border: 1px solid #ffac3f;
        color: #ffac3f;
        margin-left: 10px !important;
    }
    footer ul li,.bottom-footer p{
        font-size:14px;
    }
    
    .col-dash-left{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-dash-right{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cards-graph .col-dash-left{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .row-cards-graph .col-dash-right{
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 10px;
    }
    .wrap-adres-head{
        width: 100%;
        text-align: left;
    }
    .icons-share-deleg{
        position: absolute;
        right: 0;
        top: 0;
    }
    .dash-head h1 {
        text-align: center;
        margin-bottom: 24px;
    }
    .statcard p{
        font-size: 14px;
    }
    body .statcard{
        min-height: 105px;
    }
    .wrap-stakes-box{
        margin-left:0;
    }
    .wrap-graph-box{
        margin-right: 0;
        margin-bottom: 8px;
    }
    .dashboard .main-container,.owner .main-container{
        height: auto;
        padding: 40px 0 120px 0;
    }
}
@media screen and (max-width: 768px){
    body .body-bg{
        height: 100%;
    }
    body .body-bg{
        display: block;
    }
    body .dashboard-card{
        display: block !important;
    }
    body .btn.btn-primary, body .btn.btn-link{
        font-size: 16px;
    }
    .home .main-container{
        height: 100vh;
        width: 100%;
    }
    body .body-bg.home {
        padding-top: 10%;
    }
    .bottom-footer ul{
        justify-content: center;
    }
    footer .navbar-nav{
        flex-direction: column;
        text-align: center;
    }
    .footer-social{
        margin: 25px 0;
    }
    footer ul li{
        margin-bottom: 8px;
    }
    .bottom-footer p{
        margin-top: 16px;
    }
    footer{
        padding: 30px 0;
    }
    .footer .icon-footer{
        margin-left:15px;
        margin-right: 15px;
    }
    .bottom-footer {
        margin-top: 0;
    }
    #specialmenu {
        background-color: #161616;
    }
    .row-cards .col-card:first-child{
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 8px;
    }
    .col-card {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 8px;
    }
    .statcard p.stat-value{
        font-size: 20px;
    }
    .dash-head {
        margin-bottom: 32px;
    }

}

@media screen and (max-width: 576px){
    body .login-bg{
        padding: 15px 30px 30px 30px;
    }
    body h1{
        font-size: 20px;
        margin-bottom: 5px;
    }
    body p{
        font-size: 16px;
        line-height: 20px;
    }
    /* body .btn.btn-primary{
        margin-bottom: 16px;
    } */
    body .body-bg{
        height: 100%;
    }
    .login-text{
        font-size: 14px;
        margin-bottom: 32px;
    }
    .close-login a{
        padding: 3px 10px;
        border-radius: 6px;
    }
    .close-login img{
        width:8px
    }
    .icon-title{
        font-size: 12px;
        margin-bottom: 8px;
    }
    .icon-subtitle{
        font-size: 12px;
        margin-top: 8px;
    }
    body .login-btns .btn.btn-primary{
        padding-top: 24px !important;
        padding-bottom: 24px !important;
        padding-left: 12px !important;
        padding-right: 12px  !important;
    }
    .login-btns img{
        width: 32px;;
    }
    .create-wallet{
        margin-top: 30px;
    }
    .body-bg.body-bg.home .main-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    body .body-bg.home {
        padding-top: 0;
    }
    .footer-title{
        margin-top: 20px;
    }
    .dash-head p span{
        margin-left:0;
        margin-top: 12px;
    }
    .dash-head h1 {
        text-align: left;
        margin-bottom: 24px;
        font-size: 20px;
    }
    .wrap-adres-head{
        padding: 12px;
    }
    body .statcard{
        min-width: unset;
        padding: 16px;
    }
    .card .card-body{
        padding: 40px 30px !important;
    }
    .col-delegate{
        flex: 0 0 100%;
        max-width: 100%;
        display: flex;
        margin-bottom: 8px;
    }
    .table .table-admin td {
        padding: 7px;
    }
}
@media screen and (max-width:400px){
    body .login-bg{
        padding: 15px 5px 20px 5px;
    }
    body .login-btns .btn.btn-primary{
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    body .btn.btn-primary, body .btn.btn-link{
        margin-left: 2px !important;
        margin-right: 2px !important;
    }
}





